import Head from "next/head";
import Footer from "../Footer";
import Navigation from "../Navigation";
import BottomNav from "../Navigation/Mobile/Bottom";
import { useDispatch, useSelector } from "react-redux";
import { addInfo, removeInfo } from "@/redux/gulongph.slice";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import RecentSoldNotif from "../Notification/RecentSold";
import Announcement from "../Notification/Announcement";
const fetcher = (url) => fetch(url).then((res) => res.json());

export default function Layout({ pageTitle, children }){
    const pTitle = pageTitle ? "Gulong PH - " + pageTitle : "Gulong PH";
    const dispatch = useDispatch();
    const router = useRouter();
    const recentSoldProducts = useSelector((state) => state.gulongph.recent_sold);
    const chkAnnouncement = useSelector((state) => state.gulongph.announcement);
    const { data, error, isLoading } = useSWR(`${process.env.NEXT_PUBLIC_API_URL}/0b0a0250751ea77b323c2cf3f66e714cb4f6181b`, fetcher);

    useEffect(()=>{
        if(!data) return;
        const checkRsDt = data?.filter(d => d.type == 'recent_sold');
        const checkAnnouncement = data?.filter(d => d.type == 'announcement');
        let finalRs = [];
        if(checkRsDt.length > 0){
            let rs = [];
            let arRs = recentSoldProducts?.map(d => { return d.id });
            let dtRs = checkRsDt?.map(d => { return d.id });
            rs = recentSoldProducts ? dtRs.filter(val => !arRs.includes(val)) : checkRsDt;
            if(rs.length > 0) {
                let dtrs = [];
                if(recentSoldProducts){
                    let tempRs = rs;
                    dtrs = tempRs.map((d, x)=>{
                        let checkRs = checkRsDt.filter(v => v.id == d);
                        if(checkRs) return checkRs[0];
                    });
                }
                dispatch(addInfo({recent_sold: data}));
                finalRs = dtrs.length > 0 ? dtrs : rs;
                [...finalRs].sort(function(a,b){
                    return new Date(b.date_updated) - new Date(a.date_updated);
                });
                finalRs.forEach((d, x) => {
                    setTimeout(() => {
                        enqueueSnackbar(d.name, {
                            variant: "soldNotif",
                            autoHideDuration: 6000,
                            make: d.make,
                            date_updated: d.date_updated,
                            slug: d.slug,
                            image: d.image,
                        });
                    }, 8000 * x);
                });
            }
            if(checkAnnouncement.length > 0){
                let tm = finalRs.length > 0 ? (parseInt(finalRs.length) * 8000) + 2000 : 0;
                setTimeout(() => {
                    let annce = [];
                    let arAnnce = chkAnnouncement?.map(d => { return d.id });
                    let dtAnnce = checkAnnouncement?.map(d => { return d.id });
                    annce = chkAnnouncement ? dtAnnce.filter(val => !arAnnce.includes(val)) : checkAnnouncement;
                    if(annce.length > 0) {
                        let dtannce = [];
                        if(chkAnnouncement){
                            let tempRs = annce;
                            dtannce = tempRs.map((d, x)=>{
                                let checkRs = checkAnnouncement.filter(v => v.id == d);
                                if(checkRs) return checkRs[0];
                            });
                        }
                        dispatch(addInfo({announcement: checkAnnouncement}));
                        const finalAnnce = dtannce.length > 0 ? dtannce : annce;
                        [...finalAnnce].sort(function(a,b){
                            return new Date(b.date_updated) - new Date(a.date_updated);
                        });
                        checkAnnouncement.forEach((d, x) => {
                            setTimeout(() => {
                                enqueueSnackbar(d.message, {
                                    variant: "announcement",
                                    autoHideDuration: 6000,
                                    url: d.url,
                                    date_updated: d.date_updated,
                                    banner: d.banner,
                                });
                            }, 10000 * x);
                        });
                    }
                }, tm);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data]);

    useEffect(()=>{
        if(!router.isReady) return;
        if(router.query.utm_source){
            dispatch(addInfo({utm_source: router.query.utm_source}));
        }
        else{
            dispatch(removeInfo({utm_source: 'remove'}));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[router]);
    return(
        <SnackbarProvider
            Components={{
                soldNotif: RecentSoldNotif,
                announcement: Announcement,
            }}
            classes={{
                containerRoot: 'sm:!bottom-[14px] sm:!left-[20px] !bottom-auto !left-auto sm:!top-auto sm:!right-auto !top-2 !right-2'
            }}
            maxSnack={2}
        >
            <div className="lg:pt-[125px] pt-[150px]">
                <Head>
                    <title>{`${pTitle}`}</title>
                    <link rel="shortcut icon" href="/assets/images/gulong-icon-32x32.png" />
                    <link rel="icon" href="/assets/images/gulong-icon-32x32.png" />
                    <link rel="icon" href="/assets/images/gulong-icon-192x192.png" sizes="192x192" />
                    <link rel="apple-touch-icon-precomposed" href="/assets/images/gulong-icon-180x180.png" />
                    <meta name="title" content="Gulong.ph - The Number 1 Online Tire Shop in the Philippines"/>
                    <meta name="description" content="The Number 1 Online Tire Shop in the Philippines - FREE Installation and FREE Delivery on quality tires from trusted brands. Free installation near you. Shop now at Gulong.ph to get more savings in a click and fit away!"/>

                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content={`${process.env.NEXT_PUBLIC_NEXTAUTH_URL}`} />
                    <meta property="og:title" content="Gulong.ph - The Number 1 Online Tire Shop in the Philippines"/>
                    <meta property="og:description" content="The Number 1 Online Tire Shop in the Philippines - FREE Installation and FREE Delivery on quality tires from trusted brands. Free installation near you. Shop now at Gulong.ph to get more savings in a click and fit away!"/>
                    <meta property="og:image" content={`${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/assets/images/seo/seo_banner_icon_landscape.jpg`} />

                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:url" content={`${process.env.NEXT_PUBLIC_NEXTAUTH_URL}`} />
                    <meta property="twitter:title" content="Gulong.ph - The Number 1 Online Tire Shop in the Philippines"/>
                    <meta property="twitter:description" content="The Number 1 Online Tire Shop in the Philippines - FREE Installation and FREE Delivery on quality tires from trusted brands. Free installation near you. Shop now at Gulong.ph to get more savings in a click and fit away!"/>
                    <meta property="twitter:image" content={`${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/assets/images/seo/seo_banner_icon_landscape.jpg`} />
                </Head>
                <Navigation/>
                <main className="">
                    {children}
                </main>
                <Footer/>
                <BottomNav/>
            </div>
        </SnackbarProvider>
    )
}
