import Link from "next/link";
import { useRouter } from "next/router";

export default function AdsBanner(){
    const router = useRouter();
    //const homepageBanner = 'BUY 2&nbsp;<u>APOLLOs</u>,&nbsp;<u>BFGOODRICH</u>&nbsp;OR&nbsp;<u>YOKOHAMA</u>&nbsp;TIRES AND GET 50% OFF ON THE NEXT PAIR';
    const homepageBanner = 'WE OFFER FREE NEXT-DAY INSTALLATION, NEXT-DAY PICK UP and NEXT-DAY DELIVERY!';
    const checkoutBanner = '<u>FREE&nbsp;4 Wheel Alignment</u> for minimum order of P25,000';
    const redirectLink = '/shop?b=APOLLO--ARIVO--BFGOODRICH--DOUBLECOIN--MICHELIN--YOKOHAMA';
    return(
        <Link href={router.asPath.includes('checkout') ? router.asPath : redirectLink} passHref>
            <div className={`${(router.route == "/product/[name]") ? 'absolute overflow-hidden' : 'relative'} bg-red-100 py-px w-full cursor-pointer`}>
                <label className={`${(router.asPath.includes('checkout')) ? 'animate-none w-full' : 'sm:animate-none animate-marquee sm:w-full w-min'} flex flex-row justify-center whitespace-nowrap sm:text-center text-black text-sm tracking-wide font-semibold pt-1.5 pb-1 cursor-pointer`}>
                    {/*router.asPath.includes('checkout') ? <div className="content" dangerouslySetInnerHTML={{__html: checkoutBanner}}></div> : <div className="content" dangerouslySetInnerHTML={{__html: homepageBanner}}></div>*/}
                    <div className="content" dangerouslySetInnerHTML={{__html: homepageBanner}}></div>
                    <svg className="animate-ping w-[14px] ml-1 -mt-0.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M10.76 8.69a.76.76 0 0 0-.76.76V20.9c0 .42.34.76.76.76c.19 0 .35-.06.48-.16l1.91-1.55l1.66 3.62c.13.27.4.43.69.43c.11 0 .22 0 .33-.08l2.76-1.28c.38-.18.56-.64.36-1.01L17.28 18l2.41-.45a.88.88 0 0 0 .43-.26c.27-.32.23-.79-.12-1.08l-8.74-7.35l-.01.01a.756.756 0 0 0-.49-.18M15 10V8h5v2h-5m-1.17-5.24l2.83-2.83l1.41 1.41l-2.83 2.83l-1.41-1.41M10 0h2v5h-2V0M3.93 14.66l2.83-2.83l1.41 1.41l-2.83 2.83l-1.41-1.41m0-11.32l1.41-1.41l2.83 2.83l-1.41 1.41l-2.83-2.83M7 10H2V8h5v2"/></svg>
                </label>
            </div>
        </Link>
    );
}
