import Input from "@/components/Elements/Input";
import { IconButton, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Fragment, useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { addInfo, removeInfo } from "@/redux/gulongph.slice";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
export default function SearchBox({...props}){
    const recentSeach = useSelector((state) => state.gulongph.recent_search);
    const dispatch = useDispatch();
    const router = useRouter();
    const [suggestions, setSuggestions] = useState([]);
    const [isFocus, setIsFocus] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [loadingSearch, setLoadingSearch] = useState(false);

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    const searchProducts = async (q) => {
        if(q.length > 1){
            setLoadingSearch(true);
            const sP = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/product_search?q=${q}`);
            let sProducts = await sP.json();
            if(sProducts.length > 0){
                setSuggestions(sProducts);
            }
            else{
                setSuggestions([]);
            }
            setLoadingSearch(false);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const optimizedFn = useCallback(debounce(searchProducts),[]);

    const enterSearch = (e) => {
        if(e.keyCode == 13){
            search();
        }
        if(e.target.value.trim().length == 0){
            setSuggestions([]);
        }
    };
    const search = (val = null) => {
        dispatch(addInfo({recent_search: val ? val : inputValue}));
        setIsFocus(false);
        delete router.query.sn;
        delete router.query.car_make;
        delete router.query.car_year;
        delete router.query.car_model;
        delete router.query.section_width;
        delete router.query.aspect_ratio;
        delete router.query.rim_size;
        delete router.query.search;
        router.push({
            pathname: "/shop",
            query: { "search": val ? val : inputValue },
        },
          undefined, { shallow: true}
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'search_now',
        });
    };

    useEffect(()=>{
        if(!router.isReady) return;
        if(router.query.search){
            setInputValue(router.query.search);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[router.isReady]);

    return(
        <div {...props}>
            <div className="w-full relative">
                <Input
                    fullWidth
                    size="small"
                    id="search"
                    name="search"
                    type="text"
                    label=""
                    variant="outlined"
                    placeholder="Search for Tire Size, Brands, Pattern."
                    onKeyUp={enterSearch}
                    onFocus={(e) => {
                        setIsFocus(true);
                        optimizedFn(e.target.value);
                    }}
                    onBlur={() => {
                        if (!isHovered) {
                            setIsFocus(false);
                        }
                    }}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        setIsFocus(true);
                        optimizedFn(e.target.value);
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                onClick={()=> search()}
                                                className="bg-red-600 text-white hover:text-red-600 group !rounded p-1 -mr-2"
                                                aria-label="search tire"
                                                edge="end"
                                            >
                                                <SearchIcon className=""/>
                                            </IconButton>
                                        </InputAdornment>,
                    }}
                />
                {isFocus && (
                    <div
                        className={`shadow-lg absolute top-11 left-0 w-full overflow-y-scroll bg-white rounded-b-md ${suggestions.length > 0 ? 'h-44' : ''}`}
                        onMouseEnter={() => {
                            setIsHovered(true);
                        }}
                        onMouseLeave={() => {
                            setIsHovered(false);
                        }}
                    >
                        {(suggestions?.length == 0) && (
                            <div
                                className="px-3 py-2 italic text-gray-400"
                            >
                                {loadingSearch ? (
                                    <span>Searching tires...</span>
                                ) : (inputValue.length > 0) ? (
                                    <span>No suggetions or results. Try clicking search.</span>
                                ) : (
                                    <Fragment>
                                        {(recentSeach?.length > 0) ? (
                                            <div className="w-full">
                                                <ul>
                                                    {recentSeach.map((rs, index) => {
                                                        return(
                                                            <li key={index} className="flex flex-row items-center align-middle">
                                                                <a
                                                                    className="hover:text-blue-600 cursor-pointer flex flex-row items-center align-middle"
                                                                    onClick={()=>{
                                                                        setInputValue(rs);
                                                                        search(rs);
                                                                    }}
                                                                >
                                                                    <AccessTimeIcon className="text-gray-400 mr-2 text-sm" fontSize="small" />
                                                                    <span>{rs}</span>
                                                                </a>
                                                                <button
                                                                    type="button"
                                                                    onClick={()=>{
                                                                        dispatch(removeInfo({recent_search: rs}));
                                                                    }}
                                                                    className="text-sm text-red-600 hover:text-blue-600 mr-1 ml-auto"
                                                                >
                                                                    Remove
                                                                </button>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        ) : (
                                            <span>Enter any tire size, brands or patterns.</span>
                                        )}
                                    </Fragment>
                                )}
                            </div>
                        ) }
                        {suggestions.map((suggestion, index) => {
                            const isMatch = suggestion.model.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
                            return (
                                <div
                                    key={index}
                                    className="px-3 py-2 hover:bg-red-100 cursor-pointer font-semibold"
                                    onTouchEnd={()=>{
                                        setInputValue(suggestion.model);
                                        search(suggestion.model);
                                    }}
                                    onClick={() => {
                                        setInputValue(suggestion.model);
                                        search(suggestion.model);
                                    }}
                                >
                                    {suggestion.model}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
